<template>
  <!-- <div class="fankui_lists">
    <van-tabs v-model="active">
      <van-tab title="我的反馈" name="from">
        <div v-if="list.length > 0">
          <div
            class="fankui_list"
            v-for="(item, index) in list"
            :key="index"
            @click="toFankuiInfo(item)"
          >
            <div class="fankui_name show-overflow">{{ item.name }}</div>
            <div class="fankui_name fankui_flex">
              <div class="fankui_time">{{ item.create_time }}</div>
              <div class="fankui_status fankui_isStatus">
                <span v-if="item.status == 0"> 待处理 </span>
                <span v-if="item.status == 1"> 已处理 </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="fankui_noinfo">暂无相关信息</div>
      </van-tab>
      <van-tab title="反馈进度" name="to">
        <div v-if="list2.length > 0">
          <div
            class="fankui_list"
            v-for="item in list2"
            :key="item.id"
            @click="toFankuiInfo(item)"
          >
            <div class="fankui_name show-overflow">{{ item.name }}</div>
            <div class="fankui_name fankui_flex">
              <div class="fankui_time">{{ item.create_time }}</div>
              <div class="fankui_status fankui_isStatus">
                <span v-if="item.status == 0"> 待处理 </span>
                <span v-if="item.status == 1"> 已处理 </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="fankui_noinfo">暂无相关信息</div>
      </van-tab>
    </van-tabs>
    <div class="myInfo_text" @click="toAddFankui">
      <span class="iconfont icon-qianyue myInfo_icon_font"></span>
    </div>
  </div> -->
  <div class="fankui_noinfo">暂无此功能</div>
</template>
<script>
import "../assets/css/iconfont.css"; //引入iconfont
import { formatMinute } from "../utils/tool";
export default {
  data() {
    return {
      list: [],
      pid: "",
      list2: [],
      active: "from",
      id: "",
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    this.id = id;
    if (this.pid) {
      this.getList();
    }
    // if (!this.pid) {
    //   this.$dialog
    //     .alert({
    //       message: "拒绝访问！",
    //       showConfirmButton: false,
    //     })
    //     .then(() => {});
    // }
  },
  methods: {
    getList() {
      //0是待处理，1是已处理
      this.$axios
        .get(
          '/tag/getlist?table=message&order=desc&sort=create_time&where={"create_user":' +
            this.id +
            "}"
        )
        .then((res) => {
          this.list = res.data.data;
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].create_time = formatMinute(this.list[i].create_time);
          }
        })
        .catch(() => {});
      this.$axios
        .get(
          "/tag/getlist?table=message&order=desc&sort=create_time&from_user=" +
            this.id
        )
        .then((res) => {
          this.list2 = res.data.data;
          for (var i = 0; i < this.list2.length; i++) {
            this.list2[i].create_time = formatMinute(this.list2[i].create_time);
          }
        })
        .catch(() => {});
    },
    //前往反馈详情
    toFankuiInfo(val) {
      this.$router.push({ name: "fankuiInfo", params: { id: val.id } });
    },
    //前往问题反馈页面
    toAddFankui() {
      this.$router.push({ name: "addFankui" });
    },
  },
};
</script>
<style scoped>
.fankui_noinfo {
  text-align: center;
  margin-top: 1rem;
}
.myInfo_text {
  position: absolute;
  top: 40%;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 50%;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0.5rem 0.5rem 1rem #888888;
}
.myInfo_icon_font {
  font-size: 2rem;
}
.fankui_lists {
  width: calc(100% - 1rem);
  margin: 0 auto;
  overflow-y: scroll;
  padding-bottom: 3rem;
  box-sizing: border-box;
}
.fankui_list {
  border-bottom: 1px solid #ccc;
  height: 3.75rem;
}
.fankui_name {
  line-height: 1.825rem;
  font-size: 0.875rem;
}
.fankui_flex {
  display: flex;
  justify-content: space-between;
}
.fankui_time {
  font-size: 0.75rem;
}
.fankui_status {
  font-size: 1rem;
}
.fankui_isStatus {
  color: #f59a23;
}
.fankui_statused {
  color: #059b2f;
}
.show-overflow {
  width: 75%;
  overflow: hidden; /* 超出元素宽度范围时候不使用滚动条 */
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
.fankui_error {
  color: #f00;
}
</style>
<style>
.van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}
.fankui_lists .van-tabs--line .van-tabs__wrap {
  height: 2rem;
}
.fankui_lists .van-tab {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
